import React from "react"

import styles from "./styles.module.css";
import {isMobileOnly,isIOS} from 'react-device-detect';
import PricingCard from "../pricingCard";
import { PricingCalculator } from "../pricingCalculator";
import { navigate } from "gatsby";
import GoogleReviewsSlider from "../googleReviewsSlider";

class PricingInvestors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPricingCalculatorOpen: false
        }
    }

    handleUpdate = () => {

    }

    proceed = () => {

    }

    togglePricingCalculator = (isPricingCalculatorOpen) => {
        this.setState({isPricingCalculatorOpen});
    }

    onClickCTA = (product) => {
        navigate('/sign-up/investors', {state: {tier: product.tier}});
    }

    render() {
        const { data } = this.props;

        const { isPricingCalculatorOpen } = this.state;

        var isMobileFinal = false, isIOSFinal = false;

        if (typeof window !== "undefined") {
          isMobileFinal = isMobileOnly;
          isIOSFinal = isIOS;
        }

        console.log(data, 'dataPricing')

        return (
            <>
                <div className={[styles.pageContainer].join(' ')}>
                    <div className={styles.topContainer}>
                        <div className={[styles.title].join(' ')}>
                            {data.banner.title}
                        </div>
                        <div className={[styles.subtitle].join(' ')}>
                            {data.banner.subtitle}
                        </div>
                    </div>
                    <div className={[styles.contentContainer].join(" ")}>
                        <div className={[styles.pricingCardsContainer].join(" ")}>
                            {
                                data.products.map((product, index) => (
                                    <PricingCard product={product} isCollapsedInitial={isMobileFinal && index > 0} onClickCTA={this.onClickCTA} key={index} isWealth={false}/>
                                ))
                            }
                        </div>
                        <div className={styles.pricingCalculatorContainer}>
                            <div className={styles.pricingCalculatorTitle}>Pricing calculator</div>
                            <div className={styles.pricingCalculatorButton} onClick={()=>this.togglePricingCalculator(true)}>
                                <span>Find out how much you need to pay annually for MProfit</span>
                                <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </div>
                    <GoogleReviewsSlider />
                </div>
                {
                    isPricingCalculatorOpen &&
                    <PricingCalculator isOpen={true} togglePricingCalculator={this.togglePricingCalculator} isWealth={false}/>
                }
            </>
        );
    }
}

export default PricingInvestors
